.side-bar-container {
  height: 100%;
  background-color: var(--gray-500);
  width: 17.5rem;
  color: var(--white-100);
  border-right: 1px solid var(--gray-300);
  position: fixed;
  top: 40px;
  overflow-x: hidden;
  z-index: 2;
}

.explore-title {
  padding: 0.7rem 1rem;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;
  font-size: 14px;
}

.explore-portfolio {
  padding: 0.2rem 1.2rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  gap: 0.5rem;
  border: none;
  outline: none;
  background-color: var(--gray-500);
  color: var(--white-100);
  width: 100%;
}

.explore-portfolio:hover {
  background-color: var(--gray-400);
}

.explorer-file {
  padding: 0.3rem 3rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  color: var(--white-100);
}

.explorer-file:hover {
  background-color: var(--gray-400);
}

.icon-side-bar {
  width: 18px;
}

@media screen and (max-width: 1150px) {
  .side-bar-container {
    border-left: 1px solid var(--gray-300);
    width: 0;
    right: 0;
    top: 40px;
    transition: 0.3s ease-in;

  }
  .side-bar-container.active {
    right: 0;
    top: 40px;
    width: 300px;
    display: block;
  }

  .side-bar-container .explore-title {
    opacity: 0;
  }

  .side-bar-container.active .explore-title {
    opacity: 1;
    transition: 0.5s ease-in-out;
  }

  .side-bar-container .explore-portfolio {
    opacity: 0;
  }

  .side-bar-container.active .explore-portfolio {
    opacity: 1;
    transition: 0.4s ease;
  }

  .side-bar-container .explorer-file {
    opacity: 0;
  }

  .side-bar-container.active .explorer-file {
    opacity: 1;
    transition: 0.3s ease;
  }
  
}