.main-wrapper.projects {
  justify-content: center;
  flex-direction: column;
  padding-top: 150px;
}
.title-page-projects {
  font-size: 45px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 50px;
  margin-bottom: 100px;
  width: min(1200px, 90vw);
  justify-content: center;
  min-height: 100vh;
  max-height: auto;
}

.img-container {
  height: 177.75px;
}

.img-project {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card-project {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 350px;
  color: var(--white-100);
  background-color: var(--gray-500);
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid var(--gray-300);
  height: 450px;
  position: relative;
}

.title-project {
  color: var(--blue-200);
}

.technologies-container {
  display: flex;
  gap: 0.4rem;
  flex-wrap: wrap;
}

.span-technologies {
  border-radius: 20px;
  color: var(--blue-200);
  background-color: var(--blue-100);
  font-size: 0.8rem;
  padding: 0.2rem 0.6rem;
}

.links-container {
  position: absolute;
  display: flex;
  gap: 1.2rem;
  right: 1rem;
  bottom: 1rem;
}

.btn-container {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.btn-filter {
  background-color: var(--blue-100);
  color: var(--blue-200);
  border: none;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  transition: 0.3s;
}

.btn-filter:hover {
  background-color: var(--blue-200);
  color: var(--white-100);
}

.btn-filter.active {
  background-color: var(--blue-200);
  color: var(--white-100);
}

@media screen and (max-width: 500px) {
  .title-page-projects {
    font-size: 30px;
  }

  .main-wrapper.projects {
    padding-top: 100px;
  }
}