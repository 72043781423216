.header-container {
  background-color: var(--gray-500);
  height: 36px;
  position: fixed;
  top: 40px;
  width: 100vw; 
  left: 17.5rem;
  z-index: 1;
  border-bottom: 1.5px solid var(--gray-300);
}

.nav-container {
  display: flex;
}

.nav-bar {
  border: none;
  outline: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-right: 1.5px solid var(--gray-300);
  background-color: var(--gray-500);
  color: var(--gray-100);
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 36px;
  text-decoration: none;
  border-bottom: 1.5px solid var(--gray-300);
}

.nav-bar.active {
  border-right: 1.5px solid var(--gray-300);
  border-bottom: none;
  border-top: 2px solid var(--red-100);
  background-color: var(--gray-400);
  color: var(--white-100);
}

.icon-nav {
  width: 18px;
}

@media screen and (max-width: 1150px) {
  .header-container {
    left: 0;
  }
  .nav-bar {
    display: none;
  }
  .nav-bar.active {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-100);
  }
}
