.title-bar {
  background-color: var(--gray-500);
  border-bottom: 1px solid var(--gray-300);
  color: var(--white-100);
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
}

.title-btn {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 10px;
  left: 10px;
}

.minimize, .maximize, .close {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.close {
  background-color: #BF616A;
}

.minimize {
  background-color: #EBCB8B;
}

.maximize {
  background-color: #A3BE8C;
}
